
import $ from 'jquery';
import slick from 'slick-carousel';
function nav () {
    $('.js-toggle-button').on('click', function (e) {
        e.preventDefault();
        $('.js-toggle-nav').fadeToggle();
    });
};
function cross () {
    $('.js-toggle-button').on('click', function (e) {
        e.preventDefault();
        $('.js-toggle-burguer').toggleClass('hide');
        $('.js-toggle-cross').toggleClass('active');
    });
};
function favs(){
    $('.js-toggle-favs').on('click', function (e) {
        e.preventDefault();
       // $('.js-favs').animate({width:'toggle'},480);
       $('.js-favs').toggleClass('is-active');
        var widthbar= window.innerWidth - document.documentElement.clientWidth;

        $('body').toggleClass('c-favs__open');
        if($('body').hasClass('c-favs__open')){
            $('body').css('width','calc(100% - '+widthbar+'px)');
        }else{
            $('body').css('width','100%');
        }
        //$('body').attr('scroll','no');
        $('.js-slider').slick('refresh');

    });

    $('.js-filter').on('click',function(e){
        e.preventDefault();
        var type= $(this).attr('data-filter');
        $('.js-favsblock').hide();
        if(type=="grid"){
            $('.js-favs').removeClass('c-properties-list');
        }
        else if(type=="list"){
            $('.js-favs').addClass('c-properties-list');
        }
        $('.js-favsblock').fadeIn('normal');
    })

    $('.js-addfav').on('click',function (e) {
        e.preventDefault();
        var referencia= $(this).attr('data-referencia');
        var htmlblock=$(this).parent().parent().parent().parent().html();
        htmlblock=htmlblock.replace("€", "&#8364;");
        htmlblock=btoa(htmlblock);
        var addreferencia=setFavs(htmlblock,referencia);
        localStorage.setItem('favs',JSON.stringify(addreferencia));
        markFavs();

    });
    $('body').on('click','.js-delete',function (e) {
        e.preventDefault();
        var referencia= $(this).attr('data-referencia');
        eraseFav(referencia);
    });
    function markFavs(){
        var currentfavs=localStorage.getItem("favs");
        if(currentfavs!=""){
            var arrayFavs=JSON.parse(currentfavs);
            var addreferencia={};
            for(var prop in arrayFavs) {
                var propstoadd=$('.js-favsearch').find("[data-referencia="+prop+"]");
                propstoadd.each(function(){
                   if($(this).find('i').hasClass('far')){
                       $(this).find('i').removeClass('far').addClass('fas');
                   }
                })
            }
        }
    }
    function setFavs(htmlblock,referencia){
        var currentfavs=localStorage.getItem("favs");
        var addreferencia={};
        if(currentfavs!=""){
            var arrayFavs=JSON.parse(currentfavs);
            for(var prop in arrayFavs) {
                addreferencia[prop]= arrayFavs[prop];
            }
            addreferencia[referencia] = htmlblock;
        }else if(htmlblock!="" && referencia!=""){
            addreferencia[referencia] = htmlblock;
        }
        if (addreferencia!="") {
            var htmlfavs = '<div class="o-layout o-layout--gutter-s">';
            for (var singleref in addreferencia) {
                if(singleref!="") {
                    htmlfavs += '<div class="o-layout__item c-properties__item">' + atob(addreferencia[singleref]);
                    htmlfavs += '<div class="c-favs__delete"><a href="#" class="js-delete" data-referencia="' + singleref + '" ><i class="fal fa-times-square"></i></a></div></div>';
                }
            }
            htmlfavs += '</div>';
            $('.js-favsblock').html(htmlfavs);
            return addreferencia;
        }
    }
    function eraseFav(referencia){
        var currentfavs=localStorage.getItem("favs");
        var addreferencia={};
        if(currentfavs!=""){
            var arrayFavs=JSON.parse(currentfavs);
            for(var prop in arrayFavs) {
                if(prop!=referencia) {
                    addreferencia[prop] = arrayFavs[prop];
                }
            }
            localStorage.setItem('favs',JSON.stringify(addreferencia));
            var propstoadd=$('.c-properties__fav').find("[data-referencia="+referencia+"]");
            propstoadd.each(function(){
                if($(this).find('i').hasClass('fas')){
                    $(this).find('i').removeClass('fas').addClass('far');
                }
            });
            setFavs("","");
            markFavs();

        }
    }

    $(document).ready(function(){

        setFavs("","");
        markFavs();

    })

}

export  {nav,favs,cross};