import $ from 'jquery';
import slick from 'slick-carousel';


function mainSlider (){

    $('.js-slider').slick({
        dots: false,
        // autoplay: true,
        autoplaySpeed: 4000,
        prevArrow:'<img src="/themes/illarentals/assets/images/left.svg" class="c-slider__arrow c-slider__arrow--prev fas fa-long-arrow-alt-left"></img>',
        nextArrow:'<img src="/themes/illarentals/assets/images/right.svg" class="c-slider__arrow c-slider__arrow--next fas fa-long-arrow-alt-right"></img>',
    });

}
function reviewSlider (){
    $('.js-reviews').slick({
        dots: true,
        // autoplay: true,
        autoplaySpeed: 4000,
        prevArrow:'<i class="c-slider__chevron c-slider__chevron--left fal fa-chevron-left"></i>',
        nextArrow:'<i class="c-slider__chevron c-slider__chevron--right fal fa-chevron-right"></i>',
        //appendDots:$('js-reviews').find('.js-customdots'),
        dotsClass: 'custom-dots', //slick generates this <ul.custom-dots> within the appendDots container
        customPaging: function (slider, i) {
            console.log(slider);
            var slideNumber = (i + 1),
                totalSlides = slider.slideCount;
            return '<a class="dot" role="button" title="' + slideNumber + ' of ' + totalSlides + '"><span class="string"></span></a>';
        }
    });
}

function tipologiesSlider (){

    $('.js-tipologies').slick({
        dots: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplaySpeed: 4000,
        prevArrow:'<i class="c-slider__arrow c-slider__arrow--outerprev fas fa-long-arrow-alt-left"></i>',
        nextArrow:'<i class="c-slider__arrow c-slider__arrow--outernext fas fa-long-arrow-alt-right"></i>',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,

                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    });

}


export  {mainSlider,tipologiesSlider,reviewSlider};