require("imports-loader?$=jquery!static/glDatePicker.js")


import {mainSlider,tipologiesSlider,reviewSlider} from 'modules/slider';
import {carousel} from './modules/carousel';
import {dateselector, initBookingCalendar} from './modules/datepicker';
import {toggleSearch} from './modules/search';
import {nav,favs,cross} from './modules/nav';
import {scrollTo} from './modules/scrollto';
import {svg} from './modules/svg';
import {initInputsPlusMinus} from './modules/input-plus-minus';

mainSlider();
dateselector();
initBookingCalendar()
toggleSearch();
tipologiesSlider();
reviewSlider();
nav();
favs();
carousel();
scrollTo();
svg();
cross();
initInputsPlusMinus();