import $ from 'jquery';

function toggleSearch (){

    $('.js-toggleSearch').on('click',function(e){
        e.preventDefault();
        $('.js-toggleBlock').slideToggle();
    })
}

export  {toggleSearch};