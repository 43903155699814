
import $ from 'jquery';
import datetimepicker from 'jquery-datetimepicker';

function dateselector (){
    $('.datepicker').datetimepicker({
        timepicker: false,
        format: 'd/m/Y',
        dayOfWeekStart: 1,
        scrollInput: false,
        minDate: 0
    });
}

function initBookingCalendar() {
    let fromPicker, toPicker;
    const currentDate = new Date();
    const currentLocale = document.querySelector('html').getAttribute('lang');
    const endDate = new Date(currentDate.getFullYear()+2, 11, 31);

    fromPicker = flatpickr('.js-flatpickr.from', {
        dateFormat: 'd-m-Y',
        locale: currentLocale,
        minDate: currentDate,
        maxDate: endDate,
        onChange: function(selectedDates, dateStr, instance) {
            selectedDates[0].setDate(selectedDates[0].getDate() + 1);
            toPicker.set('minDate', selectedDates[0]);
        },
    });

    currentDate.setDate(currentDate.getDate() + 1);

    toPicker = flatpickr('.js-flatpickr.to', {
        dateFormat: 'd-m-Y',
        locale: currentLocale,
        minDate: currentDate,
        maxDate: endDate,
    });
}


export  {dateselector, initBookingCalendar};