import jQuery from 'jquery';
window.$ =  jQuery;

require('../vendor/jquery.nav.js');
function scrollTo () {
    $(document).ready(function(){
       var headerheight= $('.c-header').outerHeight()+80;
        headerheight= 0 - headerheight;
        $('.js-nav').onePageNav({
            currentClass: 'active',
            scrolldiff: headerheight
        });
    })
};

export  {scrollTo};