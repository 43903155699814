
function initInputsPlusMinus (){
    $('.c-input.c-input__selector .c-input__selector--minus').off('click').on('click', function(){
        const inputEl = $(this).parent().find('input');
        const minValue = parseInt($(this).parent().data('min')) ? parseInt($(this).parent().data('min')) : 0;
        const value = inputEl.val() == minValue ?  minValue : parseInt(inputEl.val())-1;

        $(this).parent().find('.c-input__selector--label').html(value);
        inputEl.val(value);

        if(value == minValue){
            $(this).addClass('disabled');
        } else {
            $(this).removeClass('disabled');
        }

        $(this).parent().find('.c-input__selector--plus').removeClass('disabled');
    });

    $('.c-input.c-input__selector .c-input__selector--plus').off('click').on('click', function(){
        const inputEl = $(this).parent().find('input');
        const maxValue = parseInt($(this).parent().data('max')) ? parseInt($(this).parent().data('max')) : 0;
        const value = parseInt(inputEl.val())+1 > maxValue ? maxValue : parseInt(inputEl.val())+1;

        $(this).parent().find('.c-input__selector--label').html(value);
        inputEl.val(value);

        if(value == maxValue){
            $(this).addClass('disabled');
        } else {
            $(this).removeClass('disabled');
        }

        $(this).parent().find('.c-input__selector--minus').removeClass('disabled');
    });
}


export  {initInputsPlusMinus};